import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestMessageService {

  apiUrl: string;
  local_data: any;
  local_token: any;
  stripe_url: any;
  STRIPE_PUBLISHABLE_KEY:any;
  STRIPE_Secret_key:any;
  constructor(private __http: HttpClient) {
    this.apiUrl = environment.api_url;
    this.stripe_url=environment.stripeUrl;
    this.STRIPE_PUBLISHABLE_KEY=environment.stripeToken;
    this.STRIPE_Secret_key=environment.Secret_key;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
  }
  //send message response
  sendMessageRequest(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/sendMsgRequest`, data,{
      headers: { Authorization: this.local_token }
    });
  }

  requestMessageList(): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/user/getRequestList`, {
      headers: { Authorization: this.local_token }
    });
    
  }

  //get list of all reply of msg request
  responseMessageList(): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/user/getResponseList`, {
      headers: { Authorization: this.local_token }
    });
    
  }

  //send reply of msg request
  replyMsgSent(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/replyMsgRequest`, data,{
      headers: { Authorization: this.local_token }
    });
  }



    //get list of all transaction history
    transactionhistoryList(): Observable<any> {
      this.local_token = localStorage.getItem('portal_login_token');
      return this.__http.get(`${this.apiUrl}/user/transactionhistory`,{
        headers: { Authorization: this.local_token }
      });
    }
  
}
