import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfessionService {

  apiUrl: string;
  local_data: any;
  local_token: any;

  constructor(private __http: HttpClient) { 
    this.apiUrl = environment.api_url;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
  }

  list(pageNo='0',is_blocked='',search_key='',lang_id=''): Observable<any> {
    return this.__http.get(`${this.apiUrl}/profession`,{
      params:{
        pageNo,
        is_blocked,
        search_key,
        ...(lang_id!='' && {lang_id})
      }
    });
  }
 
  details(id:any): Observable<any> {
    return this.__http.get(`${this.apiUrl}/profession/${id}`,{
      headers:{Authorization:this.local_token}
    });
  }
  edit(id:any,data:any): Observable<any> {
    return this.__http.put(`${this.apiUrl}/profession/${id}`,data,{
      headers:{Authorization:this.local_token}
    });
  }






}
